// firebaseConfig.js

import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  GoogleAuthProvider,
  OAuthProvider, 
  signInWithPopup, 
  sendSignInLinkToEmail, 
  isSignInWithEmailLink, 
  signInWithEmailLink,
  connectAuthEmulator
} from "firebase/auth";
import { 
  getFirestore,
  connectFirestoreEmulator
} from "firebase/firestore";
import {
  getFunctions,
  connectFunctionsEmulator
} from "firebase/functions";
import {
  getStorage,
  connectStorageEmulator
} from "firebase/storage";
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  emulatedServices: process.env.VUE_APP_EMULATED_SERVICES,
  appUrl: process.env.VUE_APP_BASE_URL,
  apiUrl: process.env.VUE_APP_API_URL
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

// Local
if(firebaseConfig.emulatedServices === 'true') {
  console.log('Emulated services enabled');
  const appDomain = firebaseConfig.appUrl.replace(/^http:\/\//, "");
  const [proto, hostWithPort] = firebaseConfig.appUrl.split("://");
  const [host, ] = hostWithPort.split(":");
  const authUrl = `${proto}://${host}:9099`;
  connectAuthEmulator(auth, authUrl, 9099);
  connectFirestoreEmulator(db, appDomain, 8080);
  connectFunctionsEmulator(functions, appDomain, 5001);
  connectStorageEmulator(storage, appDomain, 9199);
}

export { 
  firebaseConfig,
  auth,
  db,
  functions,
  storage,
  analytics,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink
};
