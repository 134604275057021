<!-- src/components/AddFlight.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">

    <v-card class="card-container">

      <v-card-title class="title-text">Add New Flight</v-card-title>

      <v-card-text class="scrollable-content">

          <v-row class="d-flex py-1">
            <v-col :cols="12" :md="6" class="py-0">
              <v-date-input
                v-model="selectedDate"
                label="Date"
                prepend-inner-icon="mdi-calendar"
                prepend-icon=""
                variant="outlined"
                color="primary"
                base-color="white"
                :min="minDate"
                :max="maxDate"
                dense
                class="text-field"
              ></v-date-input>
            </v-col>

            <!-- Time Picker -->
            <v-col :cols="12" :md="6" class="py-0">
              <v-text-field
                v-model="formattedTime"
                label="Time"
                prepend-inner-icon="mdi-clock-time-four-outline"
                prepend-icon=""
                variant="outlined"
                color="primary"
                base-color="white"
                readonly
                @click="timeMenu = true"
              >
                <v-menu
                  v-model="timeMenu"
                  :close-on-content-click="false"
                  activator="parent"
                  transition="scale-transition"
                >
                  <v-card>
                    <v-card-text>
                      <v-time-picker
                        v-if="timeMenu"
                        v-model="temporaryTime"
                        format="24hr"
                        full-width
                        color="primary"
                      ></v-time-picker>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cancelTime">Cancel</v-btn>
                      <v-btn text color="primary" @click="confirmTime">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-text-field>
            </v-col>
          </v-row>

        <v-textarea
          v-if="!generatingResponse"
          variant="outlined"
          class="text-field"
          label="Enter flight notes..."
          no-resize
          auto-grow
          v-model="flightNotes"
        >
        </v-textarea>
        <v-row dense v-else>
          <v-col cols="12" class="justify-center">
            <v-img src="@/assets/orb-animation.png" class="breathing-image" />
          </v-col>
          <v-col colse="12">
            <v-card-text class="processing-text">Please wait. Amelia is processing your flight notes...</v-card-text>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="close">Cancel</v-btn>
        <v-btn color="primary" variant="flat" @click="saveFlight" :loading="saveLoading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createFlight } from '@/services/api';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { VTimePicker } from 'vuetify/labs/VTimePicker';

export default {
  name: 'AddFlight',
  components: {
    VDateInput,
    VTimePicker
  },
  emits: ['flightRecordAdded', 'flightRecordError'],
  data() {
    return {
      dialog: false,
      saveLoading: false,
      generatingResponse: false, 
      userUid: null,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      selectedDate: new Date(),
      selectedTime: new Date(),
      temporaryTime: '',
      timeMenu: false,
      flightNotes: null
    };
  },
  methods: {
    open(userUid) { 
      this.userUid = userUid;
      this.dialog = true;
      this.temporaryTime = this.formatTime(this.selectedTime);
    },
    close() {
      this.selectedDate = new Date();
      this.selectedTime = new Date();
      this.temporaryTime = new Date();
      this.flightNotes = null;
      this.dialog = false;
    },
    cancelTime() {
      this.temporaryTime = this.formatTime(this.selectedTime);
      this.timeMenu = false;
    },
    confirmTime() {
      const [hours, minutes] = this.temporaryTime.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      this.selectedTime = date;
      this.timeMenu = false;
    },
    formatTime(date) {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    async saveFlight() {
      this.saveLoading = true;
      this.generatingResponse = true;
      try {
        const flightDate = new Date(this.selectedDate);
        flightDate.setHours(this.selectedTime.getHours());
        flightDate.setMinutes(this.selectedTime.getMinutes());

        await createFlight(this.userUid, this.flightNotes, flightDate);
        this.$emit('flightRecordAdded');

        // Reset fields
        this.selectedDate = new Date();
        this.selectedTime = new Date();
        this.temporaryTime = new Date();
        this.flightNotes = null;
        this.generatingResponse = false;
        this.saveLoading = false;
        this.dialog = false;
      } catch (error) {
        this.saveLoading = false;
        this.generatingResponse = false;
        console.error("Failed to create lesson:", error);
        this.$emit('flightRecordError');
      }
    }
  },
  computed: {
    formattedTime() {
      if (!this.selectedTime) return '';
      const hours = this.selectedTime.getHours();
      const minutes = this.selectedTime.getMinutes().toString().padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes} ${period}`;
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 70vw;
  max-width: 1000px;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  max-height: 80vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.text-field {
  color: white;
  margin-block: 10px;
}

.date-picker {
  color: white;
  background-color: rgb(var(--v-theme-primary));
}

:deep(.v-date-picker-month__day--selected .v-btn) {
    background-color: rgb(var(--v-theme-accent)) !important;
    color: white;
}

:deep(.v-field__field) {
  color: white !important;
}

:deep(.v-field__prepend-inner) {
  color: white !important;
}

:deep(.text-field .v-btn__content) {
  color: white !important;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.processing-text {
  text-align: center;
  color: white !important;
  font-weight: 500;
}

.breathing-image {
  height: 25vh;
  animation: breathingAnimation 1.5s ease-in-out infinite;
}

@keyframes breathingAnimation {
  0%, 100% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 90vw;
  }

  .card-container {
    margin-left: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }
}
</style>
