<!-- src/components/AddStudent.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">
      
      <v-card-title class="title-text">Add New Student</v-card-title>

      <v-card-text class="card-text-area">
        Adding a new student will enable you to see their flights and analyze progress.
      </v-card-text>

      <v-card-text>
        <v-row no-gutters class="d-flex align-center justify-center">
          <v-col cols="12">
            <v-text-field
              variant="outlined"
              class="input-field"
              label="Student Email"
              prepend-inner-icon="mdi-email"
              v-model="this.studentEmail"
              :focused="emailError"
              :error="emailError"
              :hint="emailErrorMessage"
              :class="{'email-error-padding': emailError }"
              @blur="checkEmailExists"
              @input="studentEmail = studentEmail.toLowerCase()"
            ></v-text-field>
          </v-col>
          <v-col v-if="!promptEmailInvite" cols="12">
            <v-select 
              variant="outlined"
              class="input-field"
              label="Select Course"
              :items="filteredCourses"
              item-color="primary"
              prepend-inner-icon="mdi-school"
              v-model="selectedTrainingCourse"
              ref="courseSelect"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  v-for="course in popularCourses"
                  :key="course"
                  @click="selectCourse(course)"
                >
                  <v-list-item-title>{{ course }}</v-list-item-title>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="!promptEmailInvite" cols="12">
            <v-select 
              variant="outlined"
              class="input-field"
              label="Select Training Path"
              :items="['Part 61', 'Part 141', 'Part 135', 'Part 121']"
              item-color="primary"
              prepend-inner-icon="mdi-call-split"
              v-model="this.selectedTrainingPath"
            ></v-select>
          </v-col>
          <v-col v-if="!promptEmailInvite" cols="12">
            <v-select
              variant="outlined"
              class="input-field"
              label="ACS Standard"
              item-color="primary"
              prepend-inner-icon="mdi-book"
              item-title="name"
              item-value="id"
              :items="this.standardsOptions"
              v-model="this.selectedStandard"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item class="mb-1" v-bind="props" :subtitle="item.raw.faaKey"></v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="promptEmailInvite" cols="12">
            <v-text-field
              variant="outlined"
              class="input-field"
              label="Student Name"
              prepend-inner-icon="mdi-account"
              v-model="this.studentName"
              @blur="capitalizeStudentName"
            ></v-text-field>
          </v-col>
          <v-row v-if="promptEmailInvite" class="email-invite">
            <v-col xs="6" sm="8" md="10" justify="start">
              <v-card-text>
                This student doesn't have a FlightSense account. Send them an email invite!
              </v-card-text>
            </v-col>
            <v-col xs="6" sm="4" md="2">
              <v-btn
                color="primary"
                variant="flat"
                size="small"
                hide-details
                class="px-5"
                :loading="isLoadingEmail"
                @click="sendStudentInvite"
              >
                Send Email
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="close">Cancel</v-btn>
        <v-btn color="primary" variant="flat" :loading="isLoading" :disabled="saveButtonDisabled" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/../firebaseConfig';
import { checkIfEmailExists } from '@/services/firebaseService'
import { fetchAcsStandards, addStudentToInstructor } from '@/services/api';

export default {
  name: 'AddStudent',
  emits: [
    'studentAdded',
    'studentError',
    'inviteSent',
    'inviteFailed'
  ],
  data() {
    return {
      dialog: false,
      isLoading: false,
      isLoadingEmail: false,
      instructorUid: null,
      instructorName: '',
      trainingCourses: [
        "Airline Transport Pilot",
        "Certified Flight Instructor",
        "Commercial Pilot",
        "Complex Endorsement",
        "Discovery Flight",
        "Flight Review",
        "Gyroplane Endorsement",
        "Helicopter",
        "High Altitude Endorsement",
        "High Performance Endorsement",
        "Instrument",
        "Instrument Instructor",
        "Instrument Proficiency Check",
        "Multi-Engine",
        "Multi-Engine Instructor",
        "Private Pilot",
        "Recreational Pilot",
        "Seaplane",
        "Sport Pilot",
        "Tailwheel Endorsement"
      ],
      popularCourses: ["Private Pilot", "Instrument", "Commercial Pilot"],
      standards: [],
      standardsOptions: [],
      studentEmail: null,
      studentName: null,
      selectedTrainingCourse: null,
      selectedTrainingPath: null,
      selectedStandard: null,
      emailError: false,
      emailErrorMessage: '',
      emailExists: false,
      promptEmailInvite: false,
      saveButtonDisabled: false
    };
  },
  created() {
    this.fetchStandards();
  },
  methods: {
    open(instructorUid, instructorName) {
      this.dialog = true;
      this.instructorUid = instructorUid;
      this.instructorName = instructorName;
    },
    async fetchStandards() {
      this.isLoading = true;
      try {
        this.standards = await fetchAcsStandards();
        this.standardsOptions = this.standards.map(standard => ({
            id: standard.id,
            name: standard.name,
            faaKey: standard.faaKey
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.error("Error fetching standards:", error);
      }
      this.isLoading = false;
    },
    async save() {
      this.isLoading = true;
      try {
        await addStudentToInstructor({
          instructorUid: this.instructorUid,
          studentEmail: this.studentEmail,
          trainingPath: this.selectedTrainingPath,
          trainingCourse: this.selectedTrainingCourse,
          acsStandard: this.selectedStandard,
        });
        this.$emit('studentAdded');
        this.close();
      } catch (error) {
        console.error('Error adding student:', error);
        this.$emit('studentError');
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.studentEmail = null;
      this.studentName = null;
      this.selectedTrainingCourse = null;
      this.selectedTrainingPath = null;
      this.selectedStandard = null;
      this.standardsOptions = null;
      this.promptEmailInvite = false;
      this.emailExists = false;
      this.saveButtonDisabled = false;
      this.emailError = false;
      this.emailErrorMessage = '';
    },
    selectCourse(course) {
      this.selectedTrainingCourse = course;
      this.$refs.courseSelect.blur();
    },
    async sendStudentInvite () {
      this.isLoadingEmail = true;
      try {
        const sendInviteEmail = httpsCallable(functions, 'sendInviteEmail');
        await sendInviteEmail({
          email: this.studentEmail,
          studentName: this.studentName || "Aviator",
          instructorName: this.instructorName || 'Unknown Instructor',
        });
        this.$emit('inviteSent');
        this.close();
      } catch (error) {
        console.error('Error adding student:', error);
        this.$emit('inviteFailed');
      } finally {
        this.isLoadingEmail = false;
      }
    },
    async checkEmailExists() {
      if (!this.studentEmail) {
        this.emailError = true;
        this.emailErrorMessage = 'Please enter an email address';
        return false;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.studentEmail)) {
        this.emailError = true;
        this.emailErrorMessage = 'Please enter a valid email address';
        this.saveButtonDisabled = true;
        return false;
      }
      try {
        const { emailExistsInUsers, emailExistsInActiveStudents } = await checkIfEmailExists(this.studentEmail, this.instructorUid);
        if (emailExistsInActiveStudents) {
          // If the email exists in the instructor's activeStudents collection
          this.emailExists = true;
          this.emailError = true;
          this.emailErrorMessage = 'This student is already in your dashboard';
          this.saveButtonDisabled = true;
          return false;
        } else if (emailExistsInUsers) {
          // If the email exists in the users collection but not in the activeStudents collection
          this.emailExists = true;
          this.emailError = false;
          this.emailErrorMessage = '';
          this.saveButtonDisabled = false;
          this.promptEmailInvite = false;
          return true;
        } else {
          // If the email does not exist in either collection
          this.emailExists = false;
          this.emailError = false;
          this.emailErrorMessage = '';
          this.promptEmailInvite = true;
          this.saveButtonDisabled = true; // Don't add temporary student (non-existent user)
          return false;
        }
      } catch (error) {
        console.error('Failed to check email:', error);
        this.emailError = true;
        this.emailErrorMessage = 'Error checking email. Please try again.';
        return false;
      }
    },
    capitalizeStudentName() {
      if (this.studentName) {
        this.studentName = this.studentName.replace(/\b\w/g, char => char.toUpperCase());
      }
    }
  },
  computed: {
    filteredCourses() {
      return this.trainingCourses.filter(
        course => !this.popularCourses.includes(course)
      );
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 70vw;
  max-width: 1000px;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  max-height: 80vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.card-text-area {
  color: white;
  text-align: center;
  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;
  padding-block: 0px !important;
}

.input-field {
  margin-inline: 30px;
  margin-block: 0px;
  color: white;
}

.email-error-padding {
  padding-bottom: 10px;
}

.email-invite {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 30px;
  margin-block: 10px;
  color: white;
  background-color: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 90vw;
  }

  .card-container {
    margin-left: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }

  .input-field {
    margin-inline: 0px;
  }

  .email-invite {
    margin-inline: 0px;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }
}
</style>