<!-- src/pages/LoginPage.vue -->

<template>
  <v-app-bar class="app-bar" density="compact">

    <v-app-bar-title>
      <a href="https://www.flightsense.ai" target="_blank" rel="noopener noreferrer">
        <v-img
          src="@/assets/full-logo.png"
          max-height="50px"
          contain
        ></v-img>
      </a>
    </v-app-bar-title>

    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
  </v-app-bar>

  <v-parallax :src="backgroundImage" class="main-container">
    <v-container class="my-10 d-flex align-center justify-center">
      <v-card flat class="tagline-card">
        <v-row no-gutters class="tagline-container">
          <v-fade-transition :duration="500" mode="out-in" appear>
            <v-card-title :key="currentMessageIndex" class="dynamic-message">{{ messages[currentMessageIndex] }}</v-card-title>
        </v-fade-transition>
        </v-row>
        <v-row no-gutters class="tagline-container mb-5">
          <v-card-title class="dynamic-message">with <strong>FlightSense</strong></v-card-title>
        </v-row>
      </v-card>
    </v-container>

    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" class="d-flex justify-center">
        <v-sheet class="login-card rounded" elevation="0">
          <v-row class="fill-height align-center justify-center">
            <v-col cols="12" class="justify-center">
              <div v-if="showEmailLogin">
                <v-row class="align-center justify-center mx-6 mt-10">
                  <v-text-field 
                    v-model="userEmail"
                    variant="outlined" 
                    density="comfortable"
                    type="email"
                    class="login-field"
                    label="Email address"
                    prepend-inner-icon="mdi-account"
                    autocomplete="email"
                    hide-details="true"
                    @input="userEmail = userEmail.toLowerCase()"
                  ></v-text-field>
                </v-row>
                <v-row class="justify-center mx-4 mt-10">
                  <v-card-actions>
                    <v-btn variant="tonal" class="login-button" @click="sendSignInLink">Send Email</v-btn>
                  </v-card-actions>
                </v-row>
                <v-row class="justify-center mx-4 mt-0">
                  <v-card-actions>
                    <v-btn variant="plain" density="compact" class="login-button" @click="toggleEmailLogin">Cancel</v-btn>
                  </v-card-actions>
                </v-row>
              </div>
              <div v-else>
                <v-row class="justify-center ma-4 pt-6">
                  <v-btn variant="tonal" class="login-button" prepend-icon="mdi-email" @click="toggleEmailLogin">
                    Email Login
                  </v-btn>
                </v-row>
                <v-row class="justify-center ma-4">
                  <v-btn variant="tonal" class="login-button" prepend-icon="mdi-google" @click="googleLogin">
                    Google Login
                  </v-btn>
                </v-row>
                <v-row class="justify-center ma-4">
                  <v-btn variant="tonal" class="login-button" prepend-icon="mdi-apple" @click="appleLogin">
                    Apple Login
                  </v-btn>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
        <SnackbarNotification :message="snackbar.message" :color="snackbar.color" />
      </v-col>
    </v-row>
  </v-parallax>
  
  <v-footer app height="100px" class="footer-class">
    <v-row dense height="80px" class="align-end justify-center mt-4">
      <v-btn density="comfortable" variant="plain" class="terms-button" @click="openTermsOfService">
        Terms of Service
      </v-btn>
      <v-btn density="comfortable" variant="plain" class="terms-button" @click="openPrivacyPolicy">
        Privacy Policy
      </v-btn>
      <v-card-text class="contact-text mb-0">
        info@flightsense.ai
      </v-card-text>
    </v-row>
  </v-footer>
</template>

<script>
import authService from '@/services/authService.js';
import SnackbarNotification from '../components/other/SnackbarNotification.vue';

export default {
  name: 'LoginPage',
  components: {
    SnackbarNotification
  },
  data () {
    return {
      backgroundImage: require('@/assets/landing-page-background.png'),
      logo: require('@/assets/logo.png'),
      snackbar: {
        message: '',
        color: ''
      },
      showEmailLogin: false,
      showPassword: false,
      userEmail: '',
      messages: [
        'Unlock your potential',
        'Break down regulations',
        'Master stalls',
        'Craft lesson plans',
        'Receive precise feedback',
        'Demystify lapse rates',
        'Grasp center of gravity',
        'Ace your checkride',
        'Understand hypoxia',
        'Navigate complex airspace',
        'Figure out weather patterns',
        'Improve radio communication',
        'Boost situational awareness',
        'Practice emergency procedures',
        'Hone navigation skills',
        'Decode NOTAMs',
        'Perfect landing techniques',
        'Evaluate flight maneuvers',
        'Sharpen decision-making',
        'Explore aircraft systems',
        'Evaluate flight performance',
        'Ace oral exams',
        'Analyze student progress',
        'Clarify aviation theories',
        'Streamline pre-flight checks',
        'Prepare for written exams'
      ],
      currentMessageIndex: 0,
      messageInterval: null
    }
  },
  methods: {
    googleLogin() {
      authService.googleLogin(this.$router, this.snackbar);
    },
    appleLogin() {
      authService.appleLogin(this.$router, this.snackbar);
    },
    sendSignInLink() {
      authService.sendSignInLink(this.userEmail, this.snackbar);
    },
    completeSignIn() {
      authService.completeSignIn(this.$router, this.snackbar);
    },
    toggleEmailLogin() {
      this.showEmailLogin = !this.showEmailLogin;
    },
    openTermsOfService() {
      window.open('https://drive.google.com/file/d/1bu9J9DIV-3rLCkNzpJdmxhDIf1i2rFGt/view?usp=sharing', '_blank');
    },
    openPrivacyPolicy() {
      window.open('https://drive.google.com/file/d/1oe55vJ_-aTK-mlPeeuKaF4FEuHygfsUe/view?usp=sharing', '_blank')
    },
    updateMessage() {
      let randomIndex = Math.floor(Math.random() * this.messages.length);
      while(randomIndex === this.currentMessageIndex) {
        randomIndex = Math.floor(Math.random() * this.messages.length);
      }
      this.currentMessageIndex = randomIndex;
    }
  },
  mounted() {
    this.messageInterval = setInterval(() => {this.updateMessage();}, 5000);
  },
  beforeUnmount() {
    clearInterval(this.messageInterval);
  },
  created() {
    if (!authService.isAuthenticated()) {
      this.completeSignIn();
    }
  },
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = authService.isAuthenticated();
    if (isAuthenticated) {
      next('/chat');
    } else {
      next();
    }
  }
}
</script>

<style scoped>
.app-bar {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

.main-container {
  height: 100%;
}

.footer-class {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

.tagline-card {
  background-color: rgba(200, 200, 200, 0.2);
  backdrop-filter: blur(10px);
  width: 1000px;
}

.tagline-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.dynamic-message {
  font-size: 40px;
  font-weight: 400;
  color: rgb(var(--v-theme-primary));
  text-align: center;
}

.login-card {
  height: 230px;
  width: 300px;
  background-color: rgba(var(--v-theme-primary), 0.5);
  backdrop-filter: blur(10px);
}

.login-button {
  color: white;
  min-width: 250px;
}

.logo-image {
  height: 64px;
  margin-top: 20px;
}

.terms-button {
  color: rgb(var(--v-theme-accent));
  font-weight: normal;
}

.contact-text {
  color: rgb(var(--v-theme-accent));
  font-weight: light;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.login-field {
  color: white;  
}

.email-text {
  color: rgb(var(--v-theme-accent));
  font-weight: normal;
  text-align: center;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .tagline-container {
    height: 40px;
  }

  .dynamic-message {
    font-size: 25px;
    margin-block: 5px;
  }
}
</style>
