<!-- src/components/ViewFlight.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">

    <v-card class="card-container">
      <v-card-title class="title-text">
        <div class="toggle-container">
          <v-btn-toggle 
            v-model="selectedView" 
            mandatory 
            variant="flat"
            base-color="primary"
            density="comfortable"
          >
            <v-btn value="debrief">Debrief</v-btn>
            <v-btn value="notes">Notes</v-btn>
          </v-btn-toggle>
        </div>
      </v-card-title>

      <v-row class="section-row mx-8" v-if="selectedView === 'debrief'">
          <v-icon class="ml-5">mdi-auto-fix</v-icon>
          <v-card-text class="flight-notes ma-0"> Insights generated by Amelia </v-card-text>
      </v-row>

      <v-card-text>
        <div class="scrollable-content">
          <v-card-text v-if="selectedView === 'debrief'" class="debrief-insights">
            <v-row v-for="(text, title) in parsedFlightDebrief" :key="title" class="my-0">
              <v-col cols="12" class="insights-column">
                <v-card variant="flat" color="primary">
                  <v-card-title class="mt-2">
                    {{ title }}
                  </v-card-title>
                  <v-card-text>{{ text }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="selectedView === 'notes'" class="flight-notes py-2"> 
            <div v-html="formattedFlightNotes"></div> 
          </v-card-text>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import MarkdownIt from 'markdown-it';

export default {
  name: 'ViewFlight',
  data() {
    return {
      dialog: false,
      flight: [],
      selectedView: 'debrief'
    };
  },
  methods: {
    open(flight) {
      this.dialog = true;
      this.selectedView = 'debrief';
      this.flight = flight;
    },
    close() {
      this.dialog = false;
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const date = new Date(milliseconds);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
  },
  computed: {
    parsedFlightDebrief() {
      try {
        return JSON.parse(this.flight.flightDebrief).debrief || {};
      } catch (error) {
        console.error("Failed to parse flightDebrief:", error);
        return {};
      }
    },
    formattedFlightNotes() {
      return this.flight.flightNotes ? this.flight.flightNotes.replace(/\n/g, '<br>') : '';
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 70vw;
  max-width: 1000px;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  max-height: 85vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.toggle-container {
  display: flex;
  justify-content: center;
}

.flight-subtext {
  color: rgba(var(--v-theme-accent));
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  white-space: normal;
}

.scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  min-height: 20vh;
  max-height: 55vh;
  margin-right: 10px;
  margin-block: 10px;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.section-row {
  display: flex;
  margin-inline: 10px;
  margin-top: 10px;
  align-items: center;
  background-color: rgba(var(--v-theme-accent), 0.1); 
  color: rgb(var(--v-theme-accent));
  border-radius: 5px;
}

.debrief-insights {
  margin: 10px;
  padding-block: 0px;
}

.flight-notes {
  margin: 10px;
  color: rgb(var(--v-theme-accent));
}

:deep(.flight-notes) p {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.flight-notes) h1 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.flight-notes) h2 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.flight-notes) h3 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.flight-notes) h4 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.flight-notes) h5 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.flight-notes) ul {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.flight-notes) ol {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.flight-notes) li {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.flight-notes) hr {
  margin-inline: 20px;
  border: none;
  height: 2px;
  background-color: white;
}

:deep(.flight-notes) table {
  margin-inline: 30px;
  margin-block: 20px;
  width: 90%;
  border-collapse: collapse;
}

:deep(.flight-notes) th {
  padding-block: 10px;
  text-align: center;
  border: 1px solid white;
}

:deep(.flight-notes) td {
  padding-block: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid white;
}

:deep(.flight-notes) pre {
  margin-inline: 20px;
  margin-block: 20px;
  padding: 10px;
  background-color: #282c34;
  color: #f8f8f2;
  border-radius: 5px;
  overflow: auto;
}

:deep(.flight-notes) code {
  width: 100%;
  background-color: #282c34;
  color: #f8f8f2;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

:deep(.flight-notes) pre code {
  padding: 0;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 90vw;
  }

  .card-container {
    min-height: 40vh;
    max-height: 80vh;
    margin-left: 0px;
  }

  .flight-notes {
    margin: 0px;
  }

  .scrollable-content {
    min-height: 30vh;
    max-height: 55vh;
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    min-height: 80vh;
    max-height: 100vh;
    margin-left: 0px;
  }

  .debrief-insights {
    margin-inline: 0px;
  }

  .insights-column {
    padding-inline: 0px;
  }

  .scrollable-content {
    min-height: 50vh;
    max-height: 67.5vh;
    margin-right: 0px;
  }
}
</style>
