<!-- src/components/DeleteFlight.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="card-container">

      <v-card-title class="title-text">Delete Flight</v-card-title>

      <v-card-text class="body-text">
        Are you sure you want to delete this flight record? This action cannot be undone.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="dialog = false">Cancel</v-btn>
        <v-btn 
          color="error" 
          variant="flat"
          prepend-icon="mdi-delete" 
          @click="deleteFlight(flight)"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteFlight',
  emits: ['deleteFlightRecord'],
  data() {
    return {
      dialog: false,
      flight: null
    };
  },
  methods: {
    open(flight) {
      this.dialog = true;
      this.flight = flight;
    },
    deleteFlight(flight) {
      this.dialog = false;
      this.$emit('deleteFlightRecord', flight)
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 600px;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 230px;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.body-text {
  color: white;
  text-align: center;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 70vw;
  }

  .card-container {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    margin-left: 0px;
  }
}
</style>