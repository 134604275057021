// src/index.js

import { createRouter, createWebHistory } from 'vue-router';
import { auth, analytics } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import LoginPage from '../pages/LoginPage.vue';
import ChatPage from '../pages/ChatPage.vue';
import LogPage from '../pages/LogPage.vue';
import InstructPage from '../pages/InstructPage.vue';

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
  },
  {  
    path: '/chat',
    name: 'ChatPage',
    component: ChatPage,
    meta: { requiresAuth: true }
  },
  {  
    path: '/log',
    name: 'LogPage',
    component: LogPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/instruct',
    name: 'InstructPage',
    component: InstructPage,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = await getCurrentUser();

  if (requiresAuth && !user) {
    next('/');
  } else {
    next();
  }
});

router.afterEach((to) => {
  logEvent(analytics, 'page_view', {
    screen_name: to.name,
    screen_class: to.path,
  });
});

export default router;
