<!-- src/components/CustomFiles.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">

    <v-card class="card-container">

      <v-card-title class="title-text">Custom Files</v-card-title>

       <div class="subtext-container">
        <v-card-text class="header-subtext">
          Upload custom files to make them exclusively available to your version of Amelia. This will improve the ability to provide insights tailored specifically to your unique requirements and aviation activities.
        </v-card-text>

        <v-card-text class="header-subtext-2">
          For best results, please ensure your PDF files contain selectable text, not just images of text. Scanned pages may not be processed correctly.
        </v-card-text>
      </div>

      <v-container v-if="isLoading" class="fill-height d-flex align-center justify-center">
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="white"
              size="50"
              width="2.5">
              <v-icon>mdi-airplane</v-icon>
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text v-if="!isLoading && customFiles.length" class="scrollable-content mr-2">
        <v-list rounded class="file-list">
          <v-list-item 
            v-for="(file, key) in customFiles" 
            :key="key"
            class="file-item py-5"
            rounded="lg"
          >
            {{ file.filename }}
            <template v-slot:append>
              <v-icon 
                v-if="deletingFile !== file.filename"
                @click="deleteFile(file.filename)" 
                class="delete-icon"
              >
                mdi-delete
              </v-icon>
              <v-progress-circular 
                v-else 
                indeterminate 
                color="accent" 
                size="24"
                width="3"
              />
            </template>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-text v-if="!isLoading && !customFiles.length" class="file-placeholder-text">
        No custom files to display. Add files to see them here!
      </v-card-text>

      <v-card-text class="file-input-container">
        <v-row class="file-input-row">
          <v-col cols="12" sm="10" class="file-input-col">
            <v-file-input 
              class="file-input" 
              variant="outlined"
              label="Upload custom file"
              prepend-inner-icon="mdi-file-upload"
              prepend-icon=""
              chips
              :loading="uploadingFile"
              accept=".pdf"
              show-size
              hide-details
              v-model="uploadQueue"
              :color="uploadQueue && !fileIsValid ? 'error' : 'white'"
            />
          </v-col>
          <v-col cols="12" sm="2" class="file-upload-col">
            <v-btn
              variant="flat"
              color="primary"
              :disabled="!uploadQueue || !fileIsValid"
              :loading="uploadingFile"
              @click="uploadFile"
            >
              Upload
            </v-btn>
          </v-col>
          <v-col class="py-0" cols="12" v-if="uploadQueue && !fileIsValid">
            <v-card-subtitle class="error-message"> File size must be less than 500mb </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebaseConfig';
import { uploadFileToStorage, deleteFileFromStorage } from '../../services/firebaseService.js';

export default {
  name: 'CustomFiles',
  emits: ['customFileUploaded', 'customFileDeleted', 'customFileUploadError', 'customFileDeleteError'],
  data() {
    return {
      dialog: false,
      isLoading: false,
      uploadingFile: false,
      userUid: null,
      customFiles: [],
      uploadQueue: null,
      deletingFile: null,
      fileUploaded: false
    };
  },
  methods: {
    open(userUid) {
      this.dialog = true;
      this.userUid = userUid;
      this.loadCustomFiles(this.userUid);
    },
    close() {
      this.dialog = false;
      if (this.fileUploaded) {
        window.location.reload();
      }
    },
    async loadCustomFiles() { 
      this.isLoading = true;
      const getUserCorpusFiles = httpsCallable(functions, 'getUserCorpusFiles');
      const response = await getUserCorpusFiles();
      this.customFiles = response.data ? response.data : response;
      this.isLoading = false;
    },
    async uploadFile() {
      const file = this.uploadQueue;
      if (!file) {
        return;
      }
      this.uploadingFile = true;
      const fileName = file.name;
      try {
        await uploadFileToStorage(this.userUid, file);
        await this.waitForFileProcessed(
          httpsCallable(functions, 'getUserCorpusFiles'),
          (result) => result.data.filter(f => f.filename === file.name).length > 0
        )
        this.$emit('customFileUploaded');
        this.loadCustomFiles();
      } catch (error) {
        this.$emit('customFileUploadError');
        console.error("Error during file upload:", error);
        await this.deleteFile(fileName, false);
      } finally {
        this.uploadingFile = false;
        this.uploadQueue = null;
        this.fileUploaded = true;
      }
    },
    async deleteFile(filename, notify = true) {
      this.deletingFile = filename;
      try {
        await deleteFileFromStorage(this.userUid, filename);
        await this.waitForFileProcessed(
          httpsCallable(functions, 'getUserCorpusFiles'),
          (result) => result.data.filter(f => f.filename === filename).length < 1
        )
        if(notify) this.$emit('customFileDeleted');
        this.loadCustomFiles();
      } catch (error) {
        this.$emit('customFileDeleteError');
        console.error("Error during file deletion:", error);
      } finally {
        this.deletingFile = null;
      }
    },
    async waitForFileProcessed(queryFunc, finishedCondition, maxWeightFibSeconds = 54) {
      let waited = 0;
        let nextWait = 1;
        const maxWait = maxWeightFibSeconds;
        let fileProcessed = false;
        while(!fileProcessed && waited < maxWait) {
          const result = await queryFunc();
          if (finishedCondition(result)) {
             fileProcessed = true;
          } else {
            await new Promise(resolve => setTimeout(resolve, nextWait * 1000));
            waited += nextWait;
            nextWait += nextWait;
          }
        }
        if (waited >= maxWait) throw("Unable to process file");
    }
  },
  computed: {
    fileIsValid() {
      return this.uploadQueue && this.uploadQueue.size < 500000000;
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 70vw;
  max-width: 1000px;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 70vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.subtext-container {
  color: white;
  background-color: rgba(var(--v-theme-accent), 0.1);
  border-radius: 5px;
  margin-inline: 20px;
  text-align: center;
  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;
}

.header-subtext {
  padding-inline: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.header-subtext-2 {
  padding-inline: 10px;
  padding-bottom: 10px;
  padding-top: 5px;
  font-weight: 500;
}

.file-list {
  color: white;
  background-color: transparent;
  width: 100%;
}

.file-item {
  background-color: rgba(var(--v-theme-primary), 0.3);
  margin-block: 5px;
}

.delete-icon {
  color: rgba(var(--v-theme-error));
  cursor: pointer;
}

.file-placeholder-text {
  height: 100%;
  display: flex;
  color: white;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.file-input-container {
  height: 150px;
}

:deep(.v-btn__content) {
  color: white;
}

.file-input-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-input {
  color: white;
}

.file-upload-col {
  display: flex;
  justify-content: center;
}

.error-message {
  color: white;
  padding-block: 10px;
  font-weight: 500;
  background-color: rgba(var(--v-theme-error), 0.8);
  border-radius: 5px;
}

.scrollable-content {
  height: 100%;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 80vw;
  }

  .card-container {
    height: 80vh;
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    height: 100vh;
    margin-left: 0px;
  }

  .header-subtext {
    font-size: 12px;
  }

  .header-subtext-2 {
    font-size: 12px;
  }

  .scrollable-content {
    padding-block: 5px;
  }

  .file-input-col {
    padding-block: 5px;
  }

  .file-upload-col {
    padding-block: 5px;
  }
}
</style>
