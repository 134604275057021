<!-- src/components/AssistantInformation.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">

    <v-card class="card-container">

      <v-card-title class="title-text">Assistant Information</v-card-title>

      <v-card-subtitle class="subtitle-text">We are continually improving Amelia and adding new content to her corpus of knowledge. User feedback is crucial in helping us enhance the quality and accuracy of her responses.</v-card-subtitle>
      
      <v-card-text class="scrollable-content">
        <div class="description-text">
          <v-card-subtitle>How to Use Amelia</v-card-subtitle>
          <v-card-text>
            <p>Amelia is designed to assist with aviation theory, FAA regulations, and any documented materials by the FAA. She excels at answering questions related to aviation handbooks, safety protocols, and training-related content.</p>
            <p class="pt-2">Use Amelia to expand or refresh your knowledge on aviation topics or to clarify specific aviation concepts. For the best results, interact with Amelia as you would with a knowledgeable instructor. Be thorough in your questions and don't hesitate to ask follow-up questions to deepen your understanding.</p>
          </v-card-text>
        </div>

        <div class="description-text">
          <v-card-subtitle>Limitations</v-card-subtitle>
          <v-card-text>
            <p>Amelia's knowledge is based on static documents and does not include real-time data. Responses may not always be accurate or up-to-date; please use the "Report as Inaccurate" button if you notice any discrepancies.</p>
            <p class="pt-2">Amelia cannot read maps, plan flight routes, analyze airports, or access live weather information and NOTAMs at this time. She is not a substitute for professional flight planning tools or live data sources.</p>
          </v-card-text>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AssistantInformation',
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 50vw;
  max-width: 1000px;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  max-height: 80vh;
  background-color: rgb(var(--v-theme-background));
  margin-left: 64px;
}

.title-text {
  color: rgba(var(--v-theme-primary));
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.subtitle-text {
  color: rgb(var(--v-theme-primary));
  text-align: center;
  text-wrap: break-word;
  white-space: normal;
  margin-inline: 20px;
}

.description-text {
  color: rgb(var(--v-theme-primary));
  text-wrap: break-word;
  white-space: normal;
}

.list-text {
  font-size: 0.875rem;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-primary), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-primary), 0.8);
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 80vw;
  }

  .card-container {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }

  .card-container {
    margin-left: 0px;
    height: 90vh;
  }
}
</style>
