<!-- src/components/StudentDashboard.vue -->

<template>
  <v-row align="center" class="header-row" v-if="!isLoading">

    <v-col cols="3">
      <v-btn 
        v-if="!$vuetify.display.mobile"  
        variant="text"
        color="primary" 
        prepend-icon="mdi-arrow-left"
        class="header-button" 
        @click="close()"
      >
        Student List
      </v-btn>
      <v-btn 
        v-else
        class="header-button"
        variant="tonal"
        size="small" 
        color="primary"
        icon="mdi-arrow-left"
        @click="close()"
      >
      </v-btn>
    </v-col>

    <v-col cols="6">
      <v-card-text v-if="studentName" class="student-name text-center"> {{ studentName }} </v-card-text>
      <v-card-text v-else class="name-placeholder text-center"> Unknown Student </v-card-text>
    </v-col>

    <v-col cols="3" class="d-flex justify-end">
      <v-btn 
        v-if="!$vuetify.display.mobile"
        class="header-button" 
        variant="text"
        color="primary"
        prepend-icon="mdi-account"
        @click="openStudentInformationOverlay"
      > 
        Student Information
      </v-btn>
      <v-btn 
        v-else
        class="header-button" 
        variant="tonal"
        size="small"
        color="primary"
        icon="mdi-account"
        @click="openStudentInformationOverlay"
      >
      </v-btn>
    </v-col>
  </v-row>

  <v-container fluid v-if="!isLoading && flights.length > 0" class="card-container">
    <v-row class="mt-2">
      <v-col cols="12" v-for="flight in flights" :key="flight.id">

        <v-card class="flight-card" color="primary" variant="tonal">
          <v-row>
            <v-col>
              <v-card-title> {{ formatDate(flight.timestamp) }} </v-card-title>
            </v-col>
          </v-row>

          <v-card-text v-if="flight.debriefSummary" class="flight-summary py-2">
            <v-row>
              <v-col v-for="(blurb, index) in splitSummary(flight.debriefSummary)" :key="index" cols="12" class="py-1">
                <v-card variant="tonal" color="primary">
                  <v-card-text>
                    {{ blurb }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text v-else class="flight-summary py-2"> No flight debrief summary to display. </v-card-text>

          <v-card-actions class="card-actions">
            <v-spacer></v-spacer>
            <v-btn variant="flat" color="primary" @click="openViewFlight(flight)">View</v-btn>
          </v-card-actions>
          
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-if="!isLoading && flights.length === 0" class="placeholder-container">
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-img
          src="@/assets/info-placeholder.png"
          max-width="200"
          class="mx-auto mb-3 rounded"
        ></v-img>
        <v-card-text class="placeholder-text"> No flights to display for this student. When they add flights, you'll see them here! </v-card-text>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-if="isLoading" class="fill-height d-flex align-center justify-center">
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="100"
          width="5"
      > <v-icon>mdi-airplane</v-icon>
      </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <ViewFlight ref="viewFlight"/>
  <StudentInformation 
    ref="studentInformationOverlay" 
    @updateSuccessful="handleUpdateSuccessful"
    @updateFailed="handleUpdateFailed"
  />
</template>

<script>
import { getFlights } from '@/services/api';
import ViewFlight from "@/components/flights/ViewFlight.vue";
import StudentInformation from "@/components/instruct/StudentInformation.vue";

export default {
  name: 'StudentDashboard',
  components: {
    ViewFlight,
    StudentInformation
  },
  emits: [
    'resetStudentDashboard', 
    'updateSuccessful', 
    'updateFailed'
  ],
  data() {
    return {
      isLoading: false,
      instructorUid: null,
      student: null,
      studentName: null,
      studentEmail: '',
      flights: []
    };
  },
  methods: {
    async open(student, instructorUid) {
      this.student = student;
      this.studentEmail = this.student.email;
      this.studentName = student.name;
      this.instructorUid = instructorUid;
      this.initialTrainingCourse = student.trainingCourse;
      this.initialTrainingPath = student.trainingPath;
      await this.loadData();
    },
    close() {
      this.$emit('resetStudentDashboard');
    },
    async loadData() {
      this.isLoading = true;
      await this.fetchStudentFlights();
      this.isLoading = false;
    },
    async fetchStudentFlights() {
      try {
        this.flights = await getFlights(this.student.id);
      } catch (error) {
        console.error("Error fetching flights:", error);
      }
    },
    openStudentInformationOverlay() {
      this.$refs.studentInformationOverlay.open(this.student, this.instructorUid);
    },
    openViewFlight(flight) {
      this.$refs.viewFlight.open(flight);
    },
    handleUpdateSuccessful() {
      this.$emit('updateSuccessful');
    },
    handleUpdateFailed() {
      this.$emit('updateFailed');
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = new Date(timestamp);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short'
      });
    },
    splitSummary(summary) {
      return summary.split(/\n\s*\n/).filter(paragraph => paragraph.trim() !== '');
    }
  },
  computed: {
    buttonSize() {
      return this.$vuetify.display.mobile ? 'default' : 'large';
    }
  }
};
</script>

<style scoped>
.header-row {
  margin-inline: 10px;
}

.header-button {
  margin-right: 10px;
  margin-block: 10px;
}

.student-name {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--v-theme-primary));
  font-size: 24px;
  font-weight: 500;
}

.name-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--v-theme-primary), 0.4);
  font-size: 24px;
  font-weight: 500;
}

.placeholder-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.placeholder-text {
  color: rgb(var(--v-theme-primary));
  margin: 4px;
}

.card-container {
  margin-bottom: 50px;
  overflow-y: auto;
}

.flight-card {
  padding: 10px;
  margin-inline: 50px;
}

.notes-section {
  padding-block: 0;
  padding-left: 0;
  font-weight: 500;
}

.flight-summary {
  overflow-y: visible;
}

.flight-notes {
  height: 320px;
  margin: 10px;
  overflow-y: auto;
}

.flight-notes::-webkit-scrollbar {
  width: 5px;
}

.flight-notes::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.flight-notes::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-primary), 0.3);
  border-radius: 10px;
}

.flight-notes::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-primary), 0.8);
}

.card-actions {
  height: 64px;
}

@media (max-width: 1024px) {
  .loading-indicator {
    padding-left: 0px;
  }

  .flight-card {
    margin-inline: 10px;
  }
}
</style>
