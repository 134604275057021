<!-- src/components/AccountSettings.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">

    <v-card class="card-container">

      <v-card-title class="title-text">Account Settings</v-card-title>
      <v-card-text class="scrollable-content">

        <v-row class="section-row">
          <v-col cols="1" md="1" class="d-none d-md-flex justify-center">
            <v-icon color="white">
              mdi-email
            </v-icon>
          </v-col>
          <v-col xs="12" md="11" class="py-2">
            <v-card-subtitle class="section-subtitle py-1">
              Email
            </v-card-subtitle>
            <v-card-text class="section-text py-1">
              {{ this.userEmail }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row class="section-row">
          <v-col cols="1" md="1" class="d-none d-md-flex justify-center">
            <v-icon color="white">
              mdi-account
            </v-icon>
          </v-col>
          <v-col xs="12" md="11" class="py-2">
            <v-card-subtitle class="section-subtitle py-1">
              Name
            </v-card-subtitle>
            <v-card-text class="section-text py-1">
              {{ displayName }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row class="section-row">
          <v-col cols="1" md="1" class="d-none d-md-flex justify-center">
            <v-icon color="white">
              mdi-account-convert
            </v-icon>
          </v-col>
          <v-col xs="9" sm="10" md="9" class="py-2">
            <v-card-subtitle class="section-subtitle py-1">
              Instructor Mode
            </v-card-subtitle>
            <v-card-text class="section-text py-1">
              Enable this option to access tools tailored for flight instructors.
            </v-card-text>
          </v-col>
          <v-col xs="auto" sm="auto" md="2" class="d-flex align-end justify-center" style="max-width: 80px;">
            <v-switch
              v-model="isInstructor"
              color="accent"
              hide-details
            >
            </v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn 
              color="error" 
              prepend-icon="mdi-account-cancel"
              variant="flat"
              :loading="isDeleting"
              @click="openDeleteAccount"
            >
              Delete Account
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="close">Cancel</v-btn>
        <v-btn color="primary" variant="flat" @click="saveSettings">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <DeleteAccount ref="deleteAccount" @deleteUserAccount="() => deleteUserAccount()"/>
</template>

<script>
import { Sentry } from '@/plugins/sentry.js';
import { analytics } from '../../../firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { updateUserProfile, deleteUserProfile } from '@/services/api';
import DeleteAccount from './DeleteAccount.vue';

export default {
  name: 'AccountSettings',
  components: {
    DeleteAccount
  },
  props: {
    userEmail: String,
    userName: String
  },
  emits: ['settingsUpdated', 'signOut'],
  data() {
    return {
      dialog: false,
      isInstructor: false,
      isDeleting: false
    };
  },
  methods: {
    open(isInstructor) {
      this.dialog = true;
      this.isInstructor = isInstructor;
    },
    close() {
      this.dialog = false;
      this.isInstructor = false;
    },
    async saveSettings() {
      try {
        await updateUserProfile({ isInstructor: this.isInstructor });
        this.dialog = false;
        this.$emit('settingsUpdated');
      } catch (error) {
        console.error("Error saving user settings:", error);
      }
    },
    async deleteUserAccount() {
      try {
        this.isDeleting = true;
        await deleteUserProfile();
        logEvent(analytics, 'user_profile_deleted');
        this.$emit('signOut');
      } catch (error) {
        Sentry.captureMessage("Error deleting user account");
        Sentry.captureException(error);
        console.error("Failed to delete user account:", error);
      } finally {
        this.isDeleting = false;
      }
    },
    openDeleteAccount() {
      this.$refs.deleteAccount.open();
    }
  },
  computed: {
    displayName() {
      return this.userName || "Unknown User";
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 70vw;
  max-width: 1000px;
}

.card-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  max-height: 80vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: white !important;
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.section-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-inline: 10px;
  margin-block: 10px;
  background-color: rgba(var(--v-theme-accent), 0.2);
  border-radius: 10px;
}

.section-subtitle {
  color: white;
  font-weight: 500;
}

.section-text {
  color: white;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 90vw;
  }

  .card-container {
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }
}
</style>
