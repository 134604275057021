<!-- src/components/StudentInformation.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">

    <v-card class="card-container">

      <v-card-title class="title-text">Student Information</v-card-title>

      <v-card-text class="scrollable-content">
        <v-container class="pt-0 small-container">

          <v-card-subtitle class="section-title">Basic Info</v-card-subtitle>

          <v-row dense>
            <v-col :cols="12" :md="6">
              <v-text-field
                readonly
                disabled
                label="Email"
                variant="outlined"
                prepend-inner-icon="mdi-email"
                class="text-field"
                menu-icon=""
                hide-details
                :model-value="student.email"
              ></v-text-field>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field
                readonly
                disabled
                label="Phone Number"
                variant="outlined"
                prepend-inner-icon="mdi-phone"
                class="text-field"
                menu-icon=""
                hide-details
                :model-value="student.phoneNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                readonly
                disabled
                label="Address"
                variant="outlined"
                prepend-inner-icon="mdi-map-marker"
                class="text-field"
                menu-icon=""
                hide-details
                :model-value="student.address"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-subtitle class="section-title">Course Info</v-card-subtitle>

          <v-row dense>
            <v-col :cols="12" :md="6">
              <v-select
                label="Training Course"
                variant="outlined"
                prepend-inner-icon="mdi-school"
                class="text-field"
                item-color="primary"
                hide-details
                clearable
                :items="this.trainingCourses"
                v-model="student.trainingCourse"
                @blur="handleStudentUpdate()"
              ></v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select
                label="Training Path"
                variant="outlined"
                prepend-inner-icon="mdi-call-split"
                class="text-field"
                item-color="primary"
                hide-details
                clearable
                :items="this.trainingPaths"
                v-model="student.trainingPath"
                @blur="handleStudentUpdate()"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                variant="outlined"
                class="text-field"
                label="ACS Standard"
                item-color="primary"
                prepend-inner-icon="mdi-book"
                item-title="name"
                item-value="id"
                hide-details
                clearable
                :items="this.standardsOptions"
                v-model="student.acsStandard"
                @blur="handleStudentUpdate()"
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item class="mb-1" v-bind="props" :subtitle="item.raw.faaKey"></v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-card-subtitle class="section-title">Certifications & Ratings</v-card-subtitle>

          <v-row dense>
            <v-col cols="12">
              <v-select 
                readonly
                disabled
                label="Certificates"
                variant="outlined"
                prepend-inner-icon="mdi-certificate"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.certificates"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select 
                readonly
                disabled
                label="Ratings"
                variant="outlined"
                prepend-inner-icon="mdi-weather-partly-cloudy"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.ratings"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select 
                readonly
                disabled
                label="Endorsements"
                variant="outlined"
                prepend-inner-icon="mdi-pencil-plus"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.endorsements"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select 
                readonly
                disabled
                label="Aircraft Flown"
                variant="outlined"
                prepend-inner-icon="mdi-airplane"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.aircraft"
              ></v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-select 
                readonly
                disabled
                label="Medical"
                variant="outlined"
                prepend-inner-icon="mdi-medical-bag"
                menu-icon=""
                chips
                class="text-field"
                hide-details
                :model-value="student.medical"
              ></v-select>
            </v-col>
            <v-col :cols="12" :md="6">
              <v-text-field 
                readonly
                disabled
                label="Medical Expiration"
                variant="outlined"
                prepend-inner-icon="mdi-calendar-clock"
                menu-icon=""
                class="text-field"
                hide-details
                :model-value="student.medicalExpiration"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchAcsStandards, updateStudentDetails } from '@/services/api';

export default {
  name: 'StudentInformation',
  emits: [
    'updateSuccessful', 
    'updateFailed'
  ],
  data() {
    return {
      dialog: false,
      student: null,
      instructorUid: null,
      trainingCourses: [
        "Airline Transport Pilot",
        "Certified Flight Instructor",
        "Commercial Pilot",
        "Complex Endorsement",
        "Discovery Flight",
        "Flight Review",
        "Gyroplane Endorsement",
        "Helicopter",
        "High Altitude Endorsement",
        "High Performance Endorsement",
        "Instrument",
        "Instrument Instructor",
        "Instrument Proficiency Check",
        "Multi-Engine",
        "Multi-Engine Instructor",
        "Private Pilot",
        "Recreational Pilot",
        "Seaplane",
        "Sport Pilot",
        "Tailwheel Endorsement"
      ],
      trainingPaths: [
        "Part 61",
        "Part 141",
        "Part 135",
        "Part 121"
      ],
      standards: [],
      standardsOptions: []
    };
  },
  created() {
    this.fetchStandards();
  },
  methods: {
    open(student, instructorUid) {
      this.dialog = true;
      this.student = student;
      if (student.medical === "") {
        this.student.medical = [];
      }
      this.instructorUid = instructorUid;
    },
    close () {
      this.dialog = false;
    },
    async fetchStandards() {
      this.isLoading = true;
      try {
        this.standards = await fetchAcsStandards();
        this.standardsOptions = this.standards.map(standard => ({
          id: standard.id,
          name: standard.name,
          faaKey: standard.faaKey
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.error("Error fetching standards:", error);
      }
      this.isLoading = false;
    },
    async handleStudentUpdate() {
      try {
        await updateStudentDetails(this.instructorUid, this.student.id, {
          acsStandard: this.student.acsStandard || null,
          trainingPath: this.student.trainingPath || null,
          trainingCourse: this.student.trainingCourse || null
        });
        this.$emit('updateSuccessful');
      } catch (error) {
        console.error('Error updating student details:', error);
        this.$emit('updateFailed');
      }
    }
  }
};
</script>

<style scoped>
.dialog-class {
  width: 70vw;
  max-width: 1000px;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  margin-left: 64px;
}

.title-text {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-top: 8px;
}

.scrollable-content {
  height: 100%;
  margin-right: 10px;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.text-field {
  color: white !important;
  margin-block: 10px;
}

.section-title {
  color: white;
  background-color: rgba(var(--v-theme-accent), 0.5);
  backdrop-filter: blur(10px);
  padding: 5px;
  padding-left: 10px;
  border-radius: 5px;
  margin-block: 20px;
}

@media (max-width: 1024px) {
  .dialog-class {
    width: 90vw;
  }

  .card-container {
    margin-left: 0px;
  }

  .scrollable-content {
    margin-right: 0px;
  }

  .scrollable-content::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .small-container {
    padding-inline: 0px;
  }

  .dialog-class {
    width: 100vw;
  }
}
</style>
