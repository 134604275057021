// src/store/index.js

import { createStore } from 'vuex';
import authService from '../services/authService';
import { getUserProfile } from '@/services/api';
import { db } from "../../firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";

export default createStore({
  state: {
    user: {
      uid: null,
      email: "",
      displayName: "",
      photoUrl: "",
      dateCreated: null,
      lastLogin: null,
      isInstructor: null
    },
    isLoadingUser: false,
    isSubscriptionDataLoaded: false,
    unsubscribeUserListener: null
  },
  mutations: {
    setUser(state, user) {
      state.user = { ...state.user, ...user };
    },
    setLoading(state, isLoading) {
      state.isLoadingUser = isLoading;
    },
    clearUser(state) {
      state.user = {
        uid: null,
        email: "",
        displayName: "",
        photoUrl: "",
        dateCreated: null,
        lastLogin: null,
        isInstructor: null
      };
    },
    clearUnsubscribeListener(state) {
      state.unsubscribeUserListener = null;
    }
  },
  actions: {
    async fetchUser({ commit, state }, retryCount = 0) {
      commit('setLoading', true);
      const user = authService.state.user;
      const MAX_RETRIES = 5;
      const RETRY_DELAY_MS = 2000;
    
      if (user) {
        try {
          const userRef = doc(db, "users", user.uid);
          
          if (state.unsubscribeUserListener) {
            state.unsubscribeUserListener();
            commit('clearUnsubscribeListener');
          }

          const unsubscribe = onSnapshot(userRef, async (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userProfile = await getUserProfile();  
              const userData = {
                uid: user.uid,
                email: user.email,
                displayName: userProfile.name || user.displayName || "",
                photoUrl: userProfile.photoUrl || user.photoURL || "",
                dateCreated: userProfile.dateCreated || null,
                lastLogin: userProfile.lastLogin || null,
                isInstructor: userProfile.isInstructor || false
              };  

              commit('setUser', userData);
              commit('setLoading', false);
              state.unsubscribeUserListener = unsubscribe;
            } else if (retryCount < MAX_RETRIES) {
              console.log(`Retrying fetchUser... Attempt ${retryCount + 1} of ${MAX_RETRIES}`);
              setTimeout(() => {
                this.dispatch('fetchUser', retryCount + 1);
              }, RETRY_DELAY_MS);
            } else {
              console.error('User document not found after maximum retries.');
              commit('setLoading', false);
            }
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
          commit('setLoading', false);
        }
      } else {
        console.log('No user logged in');
        commit('setLoading', false);
      }
    },
    async signOut({ commit, state }) {
      if (state.unsubscribeUserListener) {
        state.unsubscribeUserListener();
        commit('clearUnsubscribeListener');
      }
      try {
        await authService.signOut();
        commit('clearUser');
      } catch (error) {
        console.error('Sign Out Error:', error);
      }
    }
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user.uid;
    },
    user(state) {
      return state.user;
    },
    isLoadingUser(state) {
      return state.isLoadingUser;
    }
  }
});
