// plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { mdi } from 'vuetify/iconsets/mdi'

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: '#2b425c',   // Deep Blue
    secondary: '#d2917b', // Soft Peach
    accent: '#91b9c9',    // Light Blue
    info: '#517e9c',      // Medium Blue
    warning: '#e69500',   // Soft Orange
    error: '#c94e50',     // Muted Red
    success: '#4caf50',   // Friendly Green
    background: '#efeee5', // Pale Cream
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme, 
    },
  },
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
    },
  },
  defaults: {
    VAppBar: {
      elevation: 0,
    },
    global: {
      font: {
        fontFamily: 'Roboto, sans-serif',
      },
    },
  },
});
