// src/services/api.js

import { auth, firebaseConfig } from "../../firebaseConfig";

//---------------------------------------------------------------------
// User API
//---------------------------------------------------------------------

export const getUserProfile = async () => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/user`);
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const updateUserProfile = async (fieldsToUpdate) => {
  try {
    const response = await fetch(`${firebaseConfig.apiUrl}/user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
      },
      body: JSON.stringify(fieldsToUpdate),
    });

    if (!response.ok) {
      throw new Error(`Failed to update profile: ${response.statusText}`);
    }

    return await response.text();
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const deleteUserProfile = async () => {
  try {
    const response = await fetch(`${firebaseConfig.apiUrl}/user`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`,
      },
    });

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error('User not found');
      }
      throw new Error(`Failed to delete user profile: ${response.statusText}`);
    }

    return await response.text();
  } catch (error) {
    console.error('Error deleting user profile:', error);
    throw error;
  }
};

//---------------------------------------------------------------------
// Subscription API
//---------------------------------------------------------------------

export const getSubscription = async () => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/subscription`);
  } catch (error) {
    console.error('Error fetching subscription details:', error);
    throw error;
  }
};

//---------------------------------------------------------------------
// Threads API
//---------------------------------------------------------------------

export const getThreads = async () => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/threads`);
  } catch (error) {
    console.error('Error fetching threads:', error);
    throw error;
  }
}

export const getThreadSummaries = async () => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/thread-summaries`);
  } catch (error) {
    console.error('Error fetching thread summaries:', error);
    throw error;
  }
}

export const getThread = async (threadUid) => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/threads/${threadUid}`);
  } catch (error) {
    console.error('Error fetching thread:', error);
    throw error;
  }
}

export const postThread = async () => {
  try {
    return await callPost(`${firebaseConfig.apiUrl}/threads`, {});
  } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
  }
};

//---------------------------------------------------------------------
// Chat API
//---------------------------------------------------------------------

export const getChatStream = async (threadUid, model) => {
  const url = `${firebaseConfig.apiUrl}/threads/${threadUid}/chat/stream`;
  await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'text/plain',
      'Authorization': `Bearer ${await getAuthToken()}`
    }
  }).then(async (res) => {
    const reader = res.body.getReader();
    const decoder = new TextDecoder();
    let result = '';

    const responseIndex = model.activeConversationResponseIndex;
    const responseHolder = model.conversation[responseIndex]
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      result += decoder.decode(value, { stream: true });
      responseHolder.content = result;
    }
  }).catch(error => {
    console.error('Error fetching stream:', error);
    throw error;
  });
}

export const postChatMessage = async (threadUid, message) => {
  const payload = {
    message: message,
    type: 'user',
    citations: []
  }
  return await callPost(`${firebaseConfig.apiUrl}/threads/${threadUid}/messages`, payload);
}

//---------------------------------------------------------------------
// Flights API
//---------------------------------------------------------------------

export const getFlights = async (userUid) => {
  try {
    const response = await fetch(`${firebaseConfig.apiUrl}/user/${userUid}/flights`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to retrieve flights: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error retrieving flights:', error);
    throw error;
  }
};

export const createFlight = async (userUid, flightNotes, flightDate = null) => {
  try {
    const body = {
      flightNotes,
      flightDate: flightDate ? flightDate.toISOString() : null,
    };

    const response = await fetch(`${firebaseConfig.apiUrl}/user/${userUid}/flights`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Failed to create flight: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating flight:', error);
    throw error;
  }
};

export const deleteFlight = async (userUid, flightUid) => {
  try {
    const response = await fetch(`${firebaseConfig.apiUrl}/user/${userUid}/flights/${flightUid}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
      }
    });

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("Flight not found.");
      } else {
        throw new Error(`Failed to delete flight: ${response.statusText}`);
      }
    }

    return "Flight successfully deleted.";
  } catch (error) {
    console.error('Error deleting flight:', error);
    throw error;
  }
};

//---------------------------------------------------------------------
// Instructors API
//---------------------------------------------------------------------

export const getStudentsForInstructor = async (instructorUid) => {
  try {
    const response = await fetch(`${firebaseConfig.apiUrl}/instructors/${instructorUid}/students`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch students: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching students for instructor:', error);
    throw error;
  }
};

export const addStudentToInstructor = async ({ instructorUid, studentEmail, trainingPath, trainingCourse, acsStandard }) => {
  try {
    const response = await fetch(`${firebaseConfig.apiUrl}/instructors/${instructorUid}/students`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`,
      },
      body: JSON.stringify({
        studentEmail,
        trainingPath,
        trainingCourse,
        acsStandard,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to add student: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error adding student to instructor:', error);
    throw error;
  }
};

export const updateStudentDetails = async (instructorUid, studentUid, fieldsToUpdate) => {
  try {
    const response = await fetch(`${firebaseConfig.apiUrl}/instructors/${instructorUid}/students/${studentUid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`,
      },
      body: JSON.stringify(fieldsToUpdate),
    });

    if (!response.ok) {
      throw new Error(`Failed to update student details: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating student details:', error);
    throw error;
  }
};

//---------------------------------------------------------------------
// Standards API
//---------------------------------------------------------------------

export const fetchAcsStandards = async () => {
  try {
    return await callGet(`${firebaseConfig.apiUrl}/standards`);
  } catch (error) {
    console.error('Error fetching ACS standards:', error);
    throw error;
  }
};

//---------------------------------------------------------------------
// Helpers
//---------------------------------------------------------------------

async function getAuthToken() {
  return await auth.currentUser.getIdToken();
}

async function callPost(url, body) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAuthToken()}`
    },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    console.error('Error creating thread:', response.statusText);
    throw new Error(`Error creating thread: ${response.statusText}`);
  }

  const data = await response.json();
  return data;
}

async function callGet(url) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getAuthToken()}`
    }
  });

  if (!response.ok) {
    console.error('Error fetching threads:', response.statusText);
    throw new Error(`Error fetching threads: ${response.statusText}`);
  }

  const data = await response.json();
  return data;
}
