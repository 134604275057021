<!-- src/pages/MigrationPage.vue -->

<template>
  <v-app-bar class="app-bar" density="compact">

    <v-app-bar-title>
      <a href="https://www.flightsense.ai" target="_blank" rel="noopener noreferrer">
        <v-img
          src="@/assets/full-logo.png"
          max-height="50px"
          contain
        ></v-img>
      </a>
    </v-app-bar-title>

    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
  </v-app-bar>

  <v-parallax :src="backgroundImage" class="main-container">
    <v-container class="card-container">
      <v-card
        variant="flat"
        class="migration-card"
      >
        <v-card-title class="title-text">FlightSense is Taking Off on iOS!</v-card-title>
        <v-card-text class="text-center scrollable-content">
          We’re excited to announce that FlightSense is now focusing on delivering the best experience through our iOS app. 🚀
          <br><br>
          To provide you with smoother, more convenient access to all the features you love, we’re encouraging users to transition to our mobile app.
          <br><br>
          <strong>What does this mean for you?</strong>
          <br>
          - If you're an existing user, all your data and features are ready for you on the iOS app.<br>
          - Simply download the FlightSense app from the App Store and log in to continue your journey.
          <br><br>
          <strong>Why are we making this change?</strong>
          <br>
          This transition helps us focus on delivering the best tools tailored for on-the-go use with enhanced performance and updates.
          <br><br>
          <em>We’re always exploring new ways to support you and appreciate your understanding during this exciting evolution.</em>
          <br><br>
          Have questions or need assistance? Feel free to email us at <a class="support-email" href="mailto:support@flightsense.ai">support@flightsense.ai</a>.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="info"
            prepend-icon="mdi-download"
            variant="flat"
            @click="openAppStore"
          >
            Download the App
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-parallax>
</template>

<script>
export default {
  name: 'MigrationPage',
  data () {
    return {
      backgroundImage: require('@/assets/landing-page-background.png'),
      logo: require('@/assets/logo.png')
    }
  },
  methods: {
    openAppStore() {
      window.open('https://apps.apple.com/us/app/flightsense/id6736599349', '_blank');
    }
  }
}
</script>

<style scoped>
.app-bar {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

.main-container {
  height: 100%;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.migration-card {
  color: white;
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
  padding: 20px;
}

.title-text {
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  font-size: 30px;
}

.scrollable-content {
  height: 100%;
  margin-block: 10px;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-accent), 0.1);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-accent), 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-accent), 0.8);
}

.support-email {
  color: rgb(var(--v-theme-accent)); 
}

.footer-class {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

.contact-text {
  color: rgb(var(--v-theme-accent));
  font-weight: light;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

@media (max-width: 600px) {
  .scrollable-content {
    height: 400px;
  }
}
</style>
